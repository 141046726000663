import React from "react";
import Layout from "../layouts/page";
import data from "../../data/posts.json";

const { posts } = data;

export default function Post({ i, children }) {
  const { title, date, readTime, url } = posts[i];

  return (
    <Layout path="/posts/1">
      <div className="row">
        <div className="col-12 p-5">
          <img src="/posts.png" className="img-header" alt="posts" />
        </div>
      </div>
      <div className="row">
        <div className="col px-5 mb-20">
          <h2>{title}</h2>
          <h5>
            <span className="card-text muted">
              {date} • {readTime} read time
            </span>
          </h5>
          <h6>
            <span className="card-text muted"></span>
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-8 px-5">
          {children}
          <p>
            <a href={url} target="_blank" rel="noreferrer">
              Originally posted on Medium
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
}
