import React from "react";
import Post from "../../components/post";

export default function Post1() {
  return (
    <Post i={0}>
      <p>
        I'm not an extrovert. I'm not very good at making conversation with
        people I've just met. If I make the decision to speak up in a meeting, I
        have a 15-30 second debate in my head about my word choice and whether I
        should say anything at all.
      </p>
      <p>
        So when people ask me, "How did you start speaking at tech conferences?"
        The answer is always, "I dunno… I just started doing it." That statement
        could not be more true. When I decided to move to New York a few years
        ago, I didn't understand the tech community that I was relocating to.
      </p>
      <p>
        It all started at my last job, which was a start up that built
        advertising software for big companies. We were using{" "}
        <a href="https://emberjs.com/" target="_blank" rel="noreferrer">
          Ember.js
        </a>{" "}
        to build out a lot of technologies, and I had just worked on an npm
        library that gave the Aviary SDK a nice interface to work with in an
        Ember application. My coworker suggested that I give a lightning talk on
        the work I had just done at the next{" "}
        <a
          href="https://www.meetup.com/EmberJS-NYC/"
          target="_blank"
          rel="noreferrer"
        >
          Ember NYC meetup
        </a>
        . I don't remember what my response was, but I remember being open to
        it. I had been to enough meetups to see people speak for a few minutes
        or extended periods of time, and it seemed simple enough.
      </p>
      <p>
        The day of the meetup came, and to say that I was freaking out is a
        complete understatement. I look back on a lot of my fears, and they are
        honestly ridiculous.
      </p>
      <p>"No one wants to hear me talk."</p>
      <p>"Everyone will be listening if I mess up."</p>
      <p>"I don't know what to say."</p>
      <p>"I don't know enough about what I built."</p>
      <p>"Someone is going to ask me a question I can't answer."</p>
      <p>
        The entire day I kept going over and over what I would say in my head. I
        rehearsed it with my manager, and then changed it again. The meetup
        came, and it was time for me to present. I had prepared a demo to help
        lead the talk with a funny image. To be honest, I completely blacked out
        that whole talk. It could have been anywhere from 5-15 minutes. I
        remember exactly three things about it:
      </p>
      <ol>
        <li>The entire audience was male. (This made me more nervous.)</li>
        <li>
          A few people laughed at my funny image in my demo. (This made me less
          nervous.)
        </li>
        <li>2 people asked me questions. (They were great questions.)</li>
      </ol>
      <p>
        That was almost 2 years ago, and there are a few things I wish I could
        tell my younger, inexperienced self:
      </p>
      <ul>
        <li>
          99% of the audience is generally interested in what you have to say.
        </li>
        <li>99% of the audience does not care how much experience you have.</li>
        <li>
          99% of the audience wants you to succeed in this talk and everything
          you do afterwards.
        </li>
        <li>
          99% of the audience will appreciate an informative and/or educational
          talk.
        </li>
      </ul>
      <p>
        A few people asked me questions about the library I had created after I
        had left the podium. Some people tweeted me. Some people had starred the
        project on GitHub. The audience could not have been more friendly and
        interested in what I had to say. I am not sure what I had hoped to gain
        from this experience, but, looking back, the outcome could be considered
        a success.
      </p>
      <p>
        Some time later, a coworker at my next job told me that he remembered my
        lightning talk from that meetup. "What did you think?" His response? "It
        was good! I could just tell that you were really nervous." Go figure.
      </p>
      <p>
        A year later, I attended my first{" "}
        <a href="https://www.emberconf.com/" target="_blank" rel="noreferrer">
          EmberConf
        </a>
        . I had been working with Ember for about a year and a half at that
        point and wanted to take my relationship with the framework to the next
        level. Come spring, I made plans to fly out to Portland to attend.
      </p>
      <p>
        I arrived in Portland a few days early to explore the city a bit. The
        conference started on Tuesday, but there were a few workshops on Monday.
        One in particular caught my eye: A workshop for women interested in
        getting into public speaking. At that time, I had not consciously
        thought about public speaking, but the thought of any workshop that was
        only for women was intriguing if not only for the networking. First
        thing Monday morning, I went to early registration and asked the
        organizers if I could attend, and they allowed me to even though I
        hadn't signed up.
      </p>
      <p>
        Upon entering the workshop, I was amazed to see how many women there
        were. Some of the attendees were speakers at the conference, but a
        majority of them were just there to gather information. I won't go into
        too much detail about the content of the workshop (you'll just have to
        take a speaking workshop with{" "}
        <a
          href="https://twitter.com/beardigsit"
          target="_blank"
          rel="noreferrer"
        >
          Bear Douglas
        </a>{" "}
        because it is SO WORTH IT), but I was in awe at how many women that work
        in development roles were in one room.
      </p>
      <p>
        The rest of EmberConf was just as eyeopening. The conference had a wide
        range of topics from debugging to CSS to best practices, and probably
        half of the speakers were women. The selection process is anonymous, so
        the selection committee for the conference is not actively seeking out
        female speakers. It means that of the total number of submissions,
        roughly 50% must come from women. How is that possible? The Ember
        community has played an active role in encouraging women to get
        involved, and it has paid off.
      </p>
      <p>
        After EmberConf was a two-day hackathon at my current employer, Blue
        Apron. I don't know if it was the high of going straight from a
        conference into a hackathon (which I was working on an awesome project
        with a coworker), but the second day of the hackathon I woke up with
        this crazy idea. (I will later learn that if I am having a creative
        block, sleep is always the answer.) I had an idea for a conference talk
        that involved performance in Ruby code based on some benchmark tests I
        had run with some coworkers earlier in the year. I have no idea how I
        even stumbled into{" "}
        <a href="http://goruco.com/" target="_blank" rel="noreferrer">
          GORUCO
        </a>
        's (Gotham Ruby Conference) CFP page, but I spent all morning putting
        together a proposal that I hoped made sense. I didn't have anyone review
        it, but I read it over and over again out loud to make sure it made
        sense. I sent it in and then headed to the location of the hackathon to
        finish out the week.
      </p>
      <p>
        The following Monday, I attended work like any other week. Most of the
        hype of the previous week had worn off. I wasn't even sure if I had
        successfully submitted my talk, or if it was just another vivid dream
        (Dreaming about code is not new to me). I had no idea when I would hear
        back. Around 11am, I received an email with "Accepting Danielle to speak
        at GORUCO 2016" as the subject. My stomach did a full cartwheel. I
        thought, "HOLY SHIT! They picked me?!" There aren't words to describe
        this experience because I literally never thought in 100 million years
        that I would be selected.
      </p>
      <p>
        A few weeks later, I started to prepare more in depth for the talk. I
        knew my topic would be covering performance, but I wasn't sure how to
        make that interesting. I thought about it a lot, and subconsciously
        tossed the topic around my brain endlessly. About 2 weeks after my
        acceptance, I woke up with a weird idea. Instead of benchmarking my code
        normally, I could make a game out of it. It wouldn't be a full rewrite
        of Ruby's Benchmark library, but it would be a fun way to measure
        performance. I got to work, and I published the ruby-racer gem. It's
        still available on{" "}
        <a
          href="https://rubygems.org/gems/ruby-racer/"
          target="_blank"
          rel="noreferrer"
        >
          rubygems.org
        </a>
        . (Not to be confused with the-ruby-racer which had a minor version
        release this year.) This would be a perfect way to present my topic in a
        non-boring way.
      </p>
      <p>
        The day of my talk, I was more nervous than I had probably ever been in
        my life. Looking back, I don't know what I was so nervous about. I knew
        my content. I had practiced. Maybe it was the live coding. Maybe it was
        the thought of standing in front of hundreds of people and explaining a
        topic that they may or may not already know better than me. After all,
        the takeaway from my talk was "You may not know what you think you
        know." What if they already knew everything?
      </p>
      <p>
        My slot came and went. I was really happy it was in the morning, so that
        I could enjoy the rest of the conference. Again, I blacked out the whole
        talk. I think I went 2 minutes over. I think my live coding went
        smoothly. I think people clapped. It was over.
      </p>
      <p>
        GORUCO is great because they do a booze cruise after party in the
        evening after the conference. I had lots of people come up to me
        afterwards to ask me about my findings in detail and give their opinions
        and perceptions. It was great! I had no idea people cared what I had to
        say or that it would make them think.
      </p>
      <p>
        When I received the feedback from the conference, there were three
        pieces of feedback that could be summarized:
      </p>
      <ol>
        <li>
          Attendees were interested in hearing about the inner workings of Ruby.
          (I was totally wrong.)
        </li>
        <li>
          Attendees wanted me to go into even more detail about the topic. (It
          was only a 10 minute talk.)
        </li>
        <li>I had appeared nervous. (Recurring theme.)</li>
      </ol>

      <p>
        A few weeks later, a remote coworker that was visiting the office asked
        how my talk went. "Good," I replied. He then suggested that I reuse the
        topic and reapply to other conferences. "Don't let it get stale."
      </p>
      <p>
        Luckily, there's a website that lists{" "}
        <a href="https://rubyconferences.org/" target="_blank" rel="noreferrer">
          upcoming Ruby conferences
        </a>{" "}
        with their CFP and registration dates. I found 4 to submit to: 2 were
        international and 2 were national. After tweaking the proposals slightly
        for each conference, I submitted them all. Now, it was time to wait.
      </p>
      <p>
        Months passed, and I was accepted to the 2 conferences that were
        international. Blue Apron agreed to send me to one of them, so I chose
        to attend{" "}
        <a href="https://rubyday.it/" target="_blank" rel="noreferrer">
          RubyDay
        </a>
        . I spent the next few months turning a 10-minute talk into a 40-minute
        talk. The feedback I received from GORUCO was invaluable. I knew exactly
        what I needed to add. RubyDay came and went, and I was starting to feel
        more confident speaking in front of large groups of mostly technical
        people. I didn't black out the whole talk, maybe just 50% of it.
      </p>
      <p>
        It was the new year now, and I had worked hard on the presentation for
        RubyDay, so I wanted to see if I could get one more talk out of it.
        There was a regional Ruby conference in Salt Lake City,{" "}
        <a href="https://rubyhack.com/" target="_blank" rel="noreferrer">
          RubyHACK
        </a>
        , coming up in April that seemed perfect for it, so I submitted my
        conference talk one last time.
      </p>
      <p>
        At the same time I slowly had been getting involved in these
        conferences, the team I worked on at Blue Apron made some changes to
        their software that involved adding hardware to the system. One morning
        I woke up, and thought, "There's a talk here." At around that time,
        <a href="https://railsconf.org/" target="_blank" rel="noreferrer">
          RailsConf
        </a>{" "}
        was accepting proposals for talks. I got the topic approved and
        submitted the proposal. The 2 conferences were back-to-back though.
        "There is no way I will get into both, so I don't have to worry about
        that."
      </p>
      <p>
        I was wrong. The folks at RubyHACK accepted my proposal to speak. A few
        weeks later, I received my acceptance to speak at RailsConf, one of the
        single most exciting moments of my life to date.
      </p>
      <p>
        I won't go into the boring details of the next few months, you can just
        watch the output of the work on YouTube. The reception at both
        conferences was incredible, and I'm so proud of the work that I put in.
        I am also happy to report that I have stopped blacking out while
        speaking, and I have discovered a few coping mechanisms with the stress:
      </p>
      <ul>
        <li>
          Tweet ridiculous things leading up to the talk, especially on the day
          of.
        </li>
        <li>Pacing on stage helps.</li>
      </ul>
      <p>
        If you want to get into speaking at tech conferences, the TL;DR of my
        story is the following:
      </p>
      <ul>
        <li>Apply to every conference that you can.</li>
        <li>Reuse your talks. (3 times works for me.)</li>
        <li>
          Nobody knows everything, and few people think they know everything.
        </li>
        <li>
          Don't worry if attendees care what you have to say. They usually do.
          Those that don't will have already left.
        </li>
        <li>
          Speak every opportunity you can get in front of an audience. (I didn't
          get into this point, but I volunteer for Women Who Code and
          RailsBridge, which sometimes requires me to speak in front of large
          groups of people.)
        </li>
        <li>
          Most importantly, if you think you don't have anything to talk about,
          you probably do. Let it stew in your brain for a few days. It'll come
          to you when you least expect it.
        </li>
      </ul>
      <p>
        I'm still not sure what I hope to get out of these conference talks.
        What I do know is that attendees have told me its 'made them think', and
        that's enough reason for me to keep doing it.
      </p>
    </Post>
  );
}
